import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SimpleDeviceType } from '../../types/simpleDevices';
import { DARK_THEME, LIGHT_THEME } from './themeColors';

const theme = createSlice({
  name: 'theme',
  initialState: {
    themeName: 'light',
    LINKcolour: LIGHT_THEME,
    dataDisplayType: 'text',
    fullscreen: false,
    deviceType: SimpleDeviceType.Cloud,
  },
  reducers: {
    toggleTheme: state => {
      if (state.themeName === 'dark') {
        state.themeName = 'light';
        state.LINKcolour = LIGHT_THEME;
      } else {
        state.themeName = 'dark';
        state.LINKcolour = DARK_THEME;
      }
    },
    setFullscreenValue: (state, { payload }: PayloadAction<boolean>) => {
      state.fullscreen = payload;
    },
    toggleDataDisplay: state => {
      return {
        ...state,
        dataDisplayType: state.dataDisplayType === 'text' ? 'box' : 'text',
      };
    },
    setDeviceType: (state, { payload }: PayloadAction<SimpleDeviceType>) => {
      state.deviceType = payload;
    },
  },
});

export default theme.reducer;

export const { toggleTheme, setFullscreenValue, toggleDataDisplay, setDeviceType } = theme.actions;
