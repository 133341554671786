export function capitalizeFirstLetter(code: string): string {
  const firstLetter = code.charAt(0).toUpperCase();
  return `${firstLetter}${code.substring(1).toLowerCase()}`;
}

export const capitalizeFirstLetters = (text: string): string => {
  const splitStr = text.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};
