import { postCheckSession, postLogin } from 'api/account';
import { getCurrentUserDetails, getUserPreferences } from 'api/profile';
import { MyResponse } from 'api/request';
import { AppThunk, MyThunkDispatch, persistor, ThunkResponse } from 'store';
import { storeLoader } from 'store/helpers';
import { messagesSliceReset } from 'store/messages';
import { getCurrentUserPerms } from 'store/myOrganisation/api';
import {
  profileSliceReset,
  setCorporatePerms,
  setUserData,
  setUserPreferences,
} from 'store/profile';
import { selectedVesselSliceReset } from 'store/selectedVessel';
import { fetchPendingVesselInvitations } from 'store/vessels';

import { accountActions } from '.';
import { LoginResponse } from './types';

export const {
  fetchAccountStart,
  fetchAccountFailed,
  loginSuccess,
  checkSessionSuccess,
  checkSessionFailed,
  accountSliceReset,
  invitationModalDisabled,
} = accountActions;

const accountLoader = (dispatch: MyThunkDispatch, reducer: () => Promise<void>): void => {
  storeLoader(dispatch, reducer, fetchAccountStart, fetchAccountFailed);
};

export const login =
  (username: string, password: string): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      dispatch(fetchAccountStart());
      const response = await postLogin(username, password);
      const sessionKey = (response.data as LoginResponse).token;
      if (response.ok) {
        dispatch(loginSuccess(sessionKey));
        dispatch(fetchPendingVesselInvitations());
        return response;
      } else return null;
    } catch (err) {
      dispatch(fetchAccountFailed(err.toString()));
      return err;
    }
  };

export const logout = (): AppThunk => async dispatch => {
  accountLoader(dispatch, async () => {
    dispatch(accountSliceReset());
    dispatch(profileSliceReset());
    dispatch(messagesSliceReset());
    dispatch(selectedVesselSliceReset());
    await persistor.purge();
  });
};

export const disableInvitationModal = (): AppThunk => async dispatch => {
  dispatch(invitationModalDisabled());
};

export const checkSession = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAccountStart());
    await postCheckSession();
    const [userData, userPreferences] = await Promise.all([
      getCurrentUserDetails(),
      getUserPreferences(),
    ]);

    if (userData) {
      if (userData.data.corporate) {
        dispatch(getCurrentUserPerms())
          .then((response: any) => {
            dispatch(setCorporatePerms(response.payload.data));
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {});
      }

      dispatch(setUserData(userData.data));
    }
    userPreferences && dispatch(setUserPreferences(userPreferences.data));
    dispatch(checkSessionSuccess());
  } catch (err) {
    dispatch(checkSessionFailed(err.toString()));
    dispatch(accountSliceReset());
    dispatch(profileSliceReset());
    dispatch(messagesSliceReset());
    dispatch(selectedVesselSliceReset());
    persistor.purge();
    return err;
  }
};
