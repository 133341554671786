import { store } from 'store';
import { NewSimpleDevice } from 'store/simpleDevice/types';
import { SimpleDeviceType } from 'types/simpleDevices';

import { VesselSimpleDevicesSettings } from '../types/permissions';
import { MyResponse, RequestConfig, requestMiddleware } from './request';
import { AlertPaginatedType } from './selectedVessel';

export async function postSetWarningLevels(
  deviceId: string,
  payload: {
    propertyId: number;
    warnLevelLow: string;
    warnLevelHigh: string;
    criticalLevelLow: string;
    criticalLevelHigh: string;
    warnTextLow: string;
    warnTextHigh: string;
    criticalTextLow: string;
    criticalTextHigh: string;
    repeatOptions: string;
    instant: boolean;
    alertDelay: number;
    instantAlertLevel?: string;
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/${deviceId}/setPropertyWarningLevels`,
    method: 'POST',
    data: payload,
  };
  return requestMiddleware(config);
}

export async function getSimpleDevicesByType(authCode: string, type: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/simpleDevices/type/${type}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAllSimpleDevicesFlat(id: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${id}/simpleDevices/flat`,
    method: 'GET',
  });
}

export async function getAllSimpleDeviceTemplates(): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/simpleDeviceTemplates',
    method: 'GET',
  });
}

export async function postAddSimpleDevice(device: NewSimpleDevice): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${device.vesselId}/simpleDevices`,
    method: 'POST',
    data: device,
  });
}

export async function postMapProperty(
  fieldId: number,
  propertyId: number,
  vesselId: string
): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/mapSimpleDeviceField`,
    method: 'POST',
    data: {
      fieldId,
      propertyId,
    },
  });
}

export async function UnmapProperty(vesselId: string, fieldId: number): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/unmap/${fieldId}`,
    method: 'POST',
  });
}

export async function postSendSimpleCommand(
  deviceProperty: number,
  value: number | string,
  authCode: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/sendCommand',
    method: 'POST',
    data: {
      deviceProperty,
      value,
    },
  };
  return requestMiddleware(config);
}

export async function deleteSimpleDevice(vesselId: string, deviceId: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/simpleDevices/${deviceId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function postEditSimpleDeviceDetails(
  vesselId: string,
  id: number,
  name: string,
  manufacturer: string,
  model: string,
  serial: string,
  location: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/simpleDevices/${id}`,
    method: 'PUT',
    data: {
      id,
      name,
      manufacturer,
      model,
      serial,
      location,
    },
  };
  return requestMiddleware(config);
}

export async function getGraphData(
  propertyId: number,
  dateFrom: string,
  dateTo: string,
  authCode: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/simpleDevices/values?propertyId=${propertyId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postSetLocationSwitch(
  location: string,
  state: string,
  authCode: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/location/${location}/switch/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSetLocationShade(
  location: string,
  state: string,
  authCode: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/location/${location}/position/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSetMetadata(
  vesselId: string,
  id: number,
  key: string,
  value: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/simpleDevices/id/${id}/metadata?key=${key}&val=${value}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export function getCameraUrl(
  deviceType: SimpleDeviceType,
  authCode: string,
  id: number,
  type?: string
): string {
  return `${process.env.REACT_APP_API_TARGET}/vessel/${
    store.getState().selectedVessel.selectedVessel?.id
  }/cameras/stream/${id}?auth=${store.getState().account.sessionKey}`;
}
//Does not return anything until weather is added or changed to work like mfd/touchscreen.
export async function getAlerts(authCode: string): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/alerts/active`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postSilenceAllAlerts(authCode: string): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/alerts/silenceAll`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSilenceAlerts(
  authCode: string,
  alertId: string
): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/alerts/${alertId}/silence`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postAckVesselAlerts(
  authCode: string,
  alertId: string
): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/alerts/${alertId}/ack`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getAlertLogData(
  authCode: string,
  data: AlertPaginatedType
): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/alerts/history/page`,
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function getSettingsData(authCode?: string): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/simpleDevices/settings`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function setSettingsData(
  authCode: string,
  data: Partial<VesselSimpleDevicesSettings>
): Promise<MyResponse | null> {
  return null;
}

export async function getWeatherData(authCode: string): Promise<MyResponse | null> {
  return null;
}

export async function getLocations(): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/simpleDevices/locations`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export function getBackgroundImgUrl(authCode: string, id?: string): string {
  return `${process.env.REACT_APP_API_TARGET}/utility/${
    store.getState().selectedVessel.selectedVessel?.id
  }/appSettings/${id}/?darkMode=${
    store.getState().theme.themeName === 'dark' ? 'true' : 'false'
  }&portrait=false`;
}

export async function getGaugeDashboard(authCode: string, type: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/simpleDevices/dashboard/${type}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postFieldOrder(
  vesselId: string,
  deviceId: number,
  order: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/simpleDevices/setFieldOrder?field=${deviceId}&fieldOrder=${order}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postToggleGauge(
  vesselId: string,
  deviceId: number,
  include: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/simpleDevices/setIncludeInGauges?field=${deviceId}&include=${include}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}
