import { RequestConfig } from './request';

class RequestLimiter {
  requests = new Map();

  checkRequest = (config: RequestConfig): boolean => {
    if (!this.requests) {
      return false;
    }
    if (config.method !== 'GET') {
      return false;
    }
    if (config && config.url && this.requests.has(config.url)) {
      //Check the two timestamps to make sure that it has been less than 9 seconds.
      const diff = Math.abs((+new Date() - this.requests.get(config.url)) / 1000);
      if (diff < 9) {
        return true;
      }
    }
    return false;
  };

  deleteRequest = (url: string | undefined): void => {
    if (url) {
      if (url.includes('simpleDevices')) {
        this.requests.delete(url);
      }
    }
  };

  addRequest = (url: string | undefined): void => {
    if (url) {
      if (url.includes('simpleDevices')) {
        this.requests.set(url, +new Date());
      }
    }
  };
}

export default RequestLimiter;
