export const LINKcolours = {
  brandBlueLight: '#001A3E', //LIGHT - Anywhere brand blue is used in light mode
  brandBlueDark: '#1C4174', //DARK - Active buttons, toggle background and icons in normal status
  brandRedLight: '#8F0018', //LIGHT - Critical border, text and indicator. Cancel and back buttons
  brandRedDark: '#CA272F', //DARK - Critical border, text and indicator. Cancel and back buttons
  brandYellowLight: '#EBB203', //LIGHT - Warning border, text and indicator
  brandYellowDark: '#ECB200', //DARK - Warning border, text and indicator
  brandGreyLight: '#E9E9E9', //LIGHT - Toggle off background, dividing lines, button background (with brandBlueLight text), table lines and header background. Search box text. Empty text input field text.
  brandGreyVeryDark: '#323233', //DARK - Toggle off background, dividing lines, table lines and header background, inactive buttons, thermostat off
  //
  lightGrey: '#E1E1E1', //DARK - Page header text and underline and homepage where a combination of text colours are used eg for weather. Button background (with brandBlueDark text) and text colour for buttons with brandBlueDark background
  brandDarkGrey: '#5E5F64', //LIGHT - Standard text colour
  darkGrey: '#A5A5A5', //DARK - Standard text colour
  //
  brandWhite: '#FFFFFF', //LIGHT - Page background, active button text, LINK header text
  brandGreyDark: '#121212', //DARK - Page backgrounds, modal backgrounds and login/change vessel background. Opposite of white in Light Mode. Text on inactive buttons.
  brandVeryLightGrey: '#FAFAFA', //LIGHT - Alternate table rows and row highlighter to see the row you're looking at
  brandVeryDarkGrey: '#1B1B1B', //DARK - Alternate table rows and row highlighter to see the row you're looking at
  //
  darkGreyAlt: '#777777', //DARK - Arrows and grey data display boxes**
  //
  brandBlueLight10: '#E5E7EB', //LIGHT - Information display boxes background
  brandBlueDark20: '#0F1D2F', //DARK - Information display boxes background
  brandYellowLight10: '#FDF7E5', //LIGHT - Warning information display boxes background
  brandYellowDark20: '#393418', //DARK - Warning information display boxes background
  brandRedLight10: '#F4E5E7', //LIGHT - Critical information display boxes background
  brandRedDark20: '#331721', //DARK - Critical information display boxes background
};

export const LIGHT_THEME = {
  //theme based brand colours
  brandBlue: LINKcolours.brandBlueLight,
  brandRed: LINKcolours.brandRedLight,
  brandYellow: LINKcolours.brandYellowLight,
  brandGrey: LINKcolours.brandGreyLight,
  brandGreyDark: LINKcolours.brandDarkGrey,
  brandGreyLight: LINKcolours.brandGreyLight,
  brandText: LINKcolours.brandBlueLight,
  brandInactive: LINKcolours.brandGreyLight,
  //background colours
  deviceContainerBackground: LINKcolours.brandWhite,
  infoBoxBackground: LINKcolours.brandBlueLight10,
  warnBoxBackground: LINKcolours.brandYellowLight10,
  critBoxBackground: LINKcolours.brandRedLight10,
  expandedBackground: LINKcolours.brandVeryLightGrey,
  //other - misc colours
  textUnderline: LINKcolours.brandBlueLight,
  buttonTextActive: LINKcolours.brandWhite,
  arrowColour: LINKcolours.brandBlueLight,
  valueBox: LINKcolours.brandDarkGrey,
  switchButton: LINKcolours.brandWhite,
  toggleTextOn: LINKcolours.brandWhite,
  sliderBackground: LINKcolours.brandGreyLight,
  radioTextOff: LINKcolours.brandBlueLight,
};

export const DARK_THEME = {
  //theme based brand colours
  brandBlue: LINKcolours.brandBlueDark,
  brandRed: LINKcolours.brandRedDark,
  brandYellow: LINKcolours.brandYellowDark,
  brandGrey: LINKcolours.brandGreyDark,
  brandGreyDark: LINKcolours.darkGrey,
  brandGreyLight: LINKcolours.lightGrey,
  brandText: LINKcolours.lightGrey,
  brandInactive: LINKcolours.brandGreyVeryDark,
  //background colours
  deviceContainerBackground: LINKcolours.brandGreyDark,
  infoBoxBackground: LINKcolours.brandBlueDark20,
  warnBoxBackground: LINKcolours.brandYellowDark20,
  critBoxBackground: LINKcolours.brandRedDark20,
  expandedBackground: LINKcolours.brandVeryDarkGrey,
  //other - misc colours
  textUnderline: LINKcolours.brandWhite,
  buttonTextActive: LINKcolours.brandWhite,
  arrowColour: LINKcolours.darkGreyAlt,
  valueBox: LINKcolours.darkGreyAlt,
  switchButton: LINKcolours.darkGrey,
  toggleTextOn: LINKcolours.lightGrey,
  sliderBackground: LINKcolours.darkGreyAlt,
  radioTextOff: LINKcolours.darkGrey,
};
