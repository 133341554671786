import { postError } from 'api/errorReport';
import brandImage from 'assets/images/modal-top-image.jpg';
import link from 'assets/images/subscriptionTypeIcon/link-icon.png';
import Button from 'components/Button';
import TextAreaInputBeta from 'components/TextAreaInputBeta';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveError } from 'store/local/actions';
import { RootState } from 'store/rootReducer';
import styled from 'styled-components';
import { standardWhite } from 'styles/colors';
import { CriticalInfo, GeneralInfo } from 'styles/containers';
import { ErrorDetails } from 'types/errorHandler';

type Props = {
  err: ErrorDetails | null;
  frontend?: () => void;
};

export function ErrorModal({ err, frontend }: Props): ReactElement {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.profile);
  const { selectedVessel } = useSelector((state: RootState) => state.selectedVessel);
  const [comment, setComment] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (err) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [err]);

  function handleReport(): void {
    if (err) {
      setLoading(true);
      postError({
        ...err,
        comment,
        userId: userData ? userData.userId : '',
        userName: userData ? userData.displayName : '',
        vesselId: selectedVessel ? selectedVessel.id : '',
        vesselName: selectedVessel ? selectedVessel.name : '',
      })
        .catch()
        .finally(() => {
          setComment('');
          setLoading(false);
          close();
        });
    }
  }

  function close(): void {
    frontend ? frontend() : dispatch(saveError(null));
  }

  return (
    <Main style={{ display: err?.displayedMsg ? 'flex' : 'none' }}>
      {err?.displayedMsg && (
        <Card>
          <CardEssentials>
            <Image src={brandImage} />
            <ImageContainer>
              <ImagePadding>
                <Logo src={link} alt="" />
              </ImagePadding>
            </ImageContainer>
          </CardEssentials>
          <Content>
            <CriticalInfo>{err.displayedMsg ?? 'Something went wrong'}</CriticalInfo>
            <ButtonsWrapper>
              {err.status !== 418 ? (
                <>
                  <GeneralInfo>
                    Anonymous data related to this error has been generated. You can optionally add
                    a comment.
                  </GeneralInfo>
                  <TextAreaInputBeta
                    placeholder={'Comment'}
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    height="100px"
                    width="100%"
                  />
                  <Button loading={loading} onClick={handleReport}>
                    Send Report
                  </Button>
                </>
              ) : (
                <Button onClick={close}>Close</Button>
              )}
            </ButtonsWrapper>
          </Content>
        </Card>
      )}
    </Main>
  );
}

const Main = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const Card = styled.div`
  position: relative;
  width: 460px;
  min-width: 460px;
  height: fit-content;
  min-height: 300px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: ${standardWhite};
  border-radius: 10px;
  border: 1px solid #56565a;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin: 10px 0;
  button {
    margin: 10px 0 !important;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 76px;
`;

const ImagePadding = styled.div`
  padding: 8px;
  border-radius: 50%;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  background: ${standardWhite};
  overflow: hidden;
`;

const Logo = styled.img`
  border-radius: 50%;
  background: skyblue;
  width: 100px;
  height: 100px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  position: relative;
  padding: 0 20px;
  height: fit-content;
`;

const CardEssentials = styled.div`
  position: relative;
  height: 200px;
`;
