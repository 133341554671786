import 'react-hot-loader';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'utils/ErrorBoundary';

import App from './App';
import { BannerContextProvider } from './context/BannerContext';
import { HeadingContextProvider } from './context/HeadingContext';
import { TabNameContextProvider } from './context/TabNameContext';
import { MobileWarningContextProvider } from './context/MobileWarningContext';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <TabNameContextProvider>
          <HeadingContextProvider>
            <BannerContextProvider>
              <MobileWarningContextProvider>
                <App />
              </MobileWarningContextProvider>
            </BannerContextProvider>
          </HeadingContextProvider>
        </TabNameContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
