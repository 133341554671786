import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetUnreachable } from 'store/local';
import { RootState } from 'store/rootReducer';
import { CriticalInfo } from 'styles/containers';

import Modal from '../Modal';

const UnreachableComponent = (): JSX.Element | null => {
  const unreachableVisible = useSelector((state: RootState) => state.local.unreachableVisible);
  const dispatch = useDispatch();

  return unreachableVisible ? (
    <Modal
      handleClose={() => {
        dispatch(resetUnreachable());
      }}
      title={'Unreachable'}>
      <CriticalInfo style={{ margin: 0 }}>
        There is an issue with connection to the server. Check if you have a reliable connection to
        the internet. If there are no issues with your internet connection, then we apologise for
        the inconvenience. The server should be back to a normal working state in a few moments.
      </CriticalInfo>
    </Modal>
  ) : null;
};

export default UnreachableComponent;
